import { Theme } from "@emotion/react";
import merge from "lodash/merge";

import { mediaScreen } from "../tokens";
import {
  AliasPropKeys,
  aliasProps,
  AllStyles,
  ColorPropKeys,
  colorProps,
  convertToPercentage,
  convertToRem,
  flexProps,
  gridProps,
  layoutProps,
  positionLocationProps,
  PropKey,
  PropValue,
  spaceProps,
  StyleProps,
  styles,
  TypographyPropKeys,
  typographyProps,
} from "./styled-system";

export function transformPropsToStyles({ theme, ...props }: StyleProps<AllStyles> & { theme: Theme }) {
  let css: Record<string, PropValue> = {};

  for (const property in props) {
    if (!styles.includes(property)) {
      continue;
    }

    const propValue = props[property as keyof typeof props] as PropValue;

    if (property in aliasProps) {
      for (const aliasProperty of aliasProps[property as AliasPropKeys]) {
        css = transformSinglePropToStyles(theme, aliasProperty as PropKey, propValue, css);
      }
    } else {
      css = transformSinglePropToStyles(theme, property as PropKey, propValue, css);
    }
  }

  return css;
}

const transformSinglePropToStyles = (
  theme: Theme,
  property: PropKey,
  value: PropValue,
  css: Record<string, PropValue> = {}
): Record<string, PropValue> => {
  if (typeof value === "object") {
    for (const [breakpointOrPseudoKey, nestedValue] of Object.entries(value)) {
      if (breakpointOrPseudoKey === "base") {
        merge(css, transformSinglePropToStyles(theme, property, nestedValue));
      } else {
        const key =
          breakpointOrPseudoKey in theme.breakpoints
            ? mediaScreen(theme.breakpoints[breakpointOrPseudoKey as keyof Theme["breakpoints"]]) // breakpoint
            : `&:${breakpointOrPseudoKey}`; // pseudo class

        merge(css, {
          [key]: transformSinglePropToStyles(theme, property, nestedValue),
        });
      }
    }

    return css;
  }

  let transformedValue = value;

  if (
    colorProps.includes(property as ColorPropKeys) &&
    (value as keyof typeof theme.colors) in theme.colors
  ) {
    // In case of a color
    transformedValue = theme.colors[value as keyof typeof theme.colors];
  } else if (
    // In case of a fontFamily
    typographyProps.includes(property as TypographyPropKeys) &&
    (value as keyof typeof theme.fonts) in theme.fonts
  ) {
    // In case of a font family
    transformedValue = theme.fonts[value as keyof typeof theme.fonts];
  } else if (typeof value === "number") {
    if (Number.isInteger(value) && shouldConvertToRem(property)) {
      // It's a number, so convert to rem
      transformedValue = convertToRem(value);
    } else if (value > 0 && value < 1) {
      // I't s percentage so change to percentage
      transformedValue = convertToPercentage(value);
    }
  }

  return merge(css, { [property]: transformedValue });
};

const shouldConvertToRem = (property: PropKey) => {
  return (
    [
      ...layoutProps,
      ...positionLocationProps,
      ...spaceProps,
      ...gridProps,
      ...flexProps,
      ...typographyProps,
    ] as PropKey[]
  ).includes(property);
};
