import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";

import { token } from "../../tokens";

export type LoaderColorType = "blue" | "white";
export type LoaderVariantType = "regular" | "fullScreen" | "small";

export const FullscreenLoader = styled(Div100vh)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DefaultLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 51.4%);
`;

const bounce = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
`;

export const SpinnerBase = styled.div<{ color?: LoaderColorType; variant?: LoaderVariantType }>`
  width: 4.375rem;
  margin: 6.25rem auto 0;
  text-align: center;

  > div {
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    animation: ${bounce} 1.4s infinite ease-in-out both;
    border-radius: 100%;
    background-color: ${(p) =>
      p.color === "white" ? token("colors.white")(p) : token("colors.bloxBlueDark")(p)};

    &:first-of-type {
      animation-delay: -0.32s;
    }

    &:nth-of-type(2) {
      animation-delay: -0.16s;
    }
  }

  ${(p) =>
    p.variant === "small" &&
    css`
      width: 100%;
      margin: 0;
    `}
`;
