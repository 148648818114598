import styled from "@emotion/styled";
import React, { ReactNode, SelectHTMLAttributes } from "react";

import { InputWrapper, InputWrapperInput } from "../Input/Input.style";

export const InputWrapperSelect = styled(InputWrapperInput)`
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 0.1875rem);
    right: 0.625rem;
    width: 0.6875rem;
    height: 0.375rem;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTEnIGhlaWdodD0nNicgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAuMzUxIDEuNTNhLjQ2My40NjMgMCAwMDAtLjY4TDkuNjM2LjE2OGEuNTIuNTIgMCAwMC0uNzEyIDBMNS41MjkgMy40NDcgMi4xMDYuMTQyYS41Mi41MiAwIDAwLS43MTMgMGwtLjcxNS42OGEuNDY2LjQ2NiAwIDAwMCAuNjhsNC40OTQgNC4zMjdhLjUyNi41MjYgMCAwMC43MTQgMGw0LjQ2NS00LjN6JyBmaWxsPScjQTFCMkM0JyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz48L3N2Zz4=");
    background-size: contain;
  }
`;

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: ReactNode;
  options?: { label: string; value: string | number }[];
}

export function Select({ name, label, options, ...rest }: SelectProps) {
  return (
    <InputWrapper>
      {label && <label htmlFor={name}>{label}</label>}
      <InputWrapperSelect>
        <select id={name} name={name} {...rest}>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </InputWrapperSelect>
    </InputWrapper>
  );
}
