import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { hideVisually } from "polished";

import { buttonReset } from "../../mixins";
import { colorModeValue, darkMode, token } from "../../tokens";
import { Flex } from "../Flex";

export const ToggleButtonBase = styled(Flex)<{ stretch?: boolean; border?: boolean }>`
  align-items: center;
  gap: 0.25rem;

  ${(p) =>
    p.stretch &&
    css`
      width: 100%;
    `}

  ${(p) =>
    p.border &&
    css`
      padding: 0.375rem 0.5rem;
      border: 1px solid ${token("colors.border")(p)};
      border-radius: ${token("radius.regular")(p)};
      background-color: ${token("colors.white")(p)};
    `}

  &:focus-within {
    border-color: ${colorModeValue(token("colors.button"), token("colors.default"))};
  }
`;

export const ToggleButtonOption = styled.button<{ active: boolean }>`
  ${buttonReset};

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0.75rem;
  transition: 0.2s ease all;
  border-radius: ${token("radius.medium")};
  color: ${token("colors.dark")};
  font-weight: 600;
  white-space: nowrap;
  gap: 0.3125rem;

  ${(p) =>
    p.active
      ? css`
          background-color: ${colorModeValue(
            token("colors.bloxBlueLightest"),
            token("colors.background")
          )(p)};
          color: ${colorModeValue(token("colors.bloxBlueDarker"), token("colors.black"))(p)};
        `
      : css`
          cursor: pointer;

          &:hover,
          &:focus {
            background-color: ${token("colors.lighter")(p)};

            ${darkMode()} {
              color: ${token("colors.darker")(p)};
            }
          }

          &:active {
            color: ${colorModeValue(token("colors.darker"), token("colors.black"))(p)};
          }
        `}

  input {
    ${hideVisually()};
  }
`;
