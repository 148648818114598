import { BaseTheme, BloxThemeProvider, Loader } from "@blox/design-system";
import { Theme, ThemeProvider } from "@emotion/react";
import merge from "lodash/merge";
import React from "react";

import Auth from "./Auth";
import { CookieManagement } from "./components/CookieManagement/CookieManagement";
import { ErrorLarge } from "./components/Error/Error";
import { Suspender } from "./components/Suspender/Suspender";
import LocalizeProvider from "./context/LocalizeProvider";
import { ModalProvider } from "./context/ModalProvider";
import PageTitleProvider from "./context/PageTitleProvider";
import { useReferralVoucher } from "./hooks/useReferralVoucher";
import { useMutateStore, useStore } from "./hooks/useStore";
import Maintenance from "./screens/Maintenance/Maintenance";
import { GlobalStyle } from "./style/globalStyle";
import { themeOverrides } from "./style/themeOverrides";

const adjustedTheme = (ancestorTheme: BaseTheme) => merge(ancestorTheme, themeOverrides) as Theme;

/**
 * Extra App wrapper container, to make sure hot module reloading works when the design-system is changed.
 * Otherwise when index.tsx was reloaded, there would be a new instance of the ReactKeycloakProvider, which crashed the app
 */
const App = React.lazy(() => import("./App"));

export function AppContainer() {
  const colorScheme = useStore((s) => s.colorScheme);
  const mutateStore = useMutateStore();

  useReferralVoucher();

  return (
    <BloxThemeProvider
      darkModeEnabled
      getStoredItem={() => colorScheme}
      setStorageItem={(colorScheme) =>
        mutateStore((s) => {
          s.colorScheme = colorScheme;
        })
      }
    >
      <ThemeProvider theme={adjustedTheme}>
        <GlobalStyle />
        <LocalizeProvider>
          <Suspender fallback={<Loader variant="fullScreen" />} ErrorComponent={ErrorLarge}>
            <CookieManagement>
              <ModalProvider>
                <PageTitleProvider>
                  <Auth>
                    <Maintenance>
                      <App />
                    </Maintenance>
                  </Auth>
                </PageTitleProvider>
              </ModalProvider>
            </CookieManagement>
          </Suspender>
        </LocalizeProvider>
      </ThemeProvider>
    </BloxThemeProvider>
  );
}
