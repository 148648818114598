import { lightColors } from "./lightColors";
import { Colors } from "./theme";

export const darkColors: Record<Colors, string> = {
  ...lightColors,
  black: "#FCFCFC",
  darker: "#D9DEE3",
  dark: "#88939d",
  default: "#7F8386",
  light: "#363B47",
  lighter: "#242832",
  offWhite: "#171A24",
  button: "#d9e0e7",
  white: "#242832",
  background: "#171A24",
  border: "#363B47",
};
