import ContentLoader from "react-content-loader";

import { useIsDarkMode } from "../../../hooks/useIsDarkMode";
import getColor from "../../../utils/getColor";

export type DataTableSkeletonProps = { rows: number };

export function DataTableSkeleton({ rows }: DataTableSkeletonProps) {
  const isDarkMode = useIsDarkMode();

  return (
    <>
      {Array(rows)
        .fill(null)
        .map((_, index) => (
          <div key={index} className="itemwrapper">
            <ContentLoader
              backgroundColor={getColor(isDarkMode ? "lighter" : "light")}
              foregroundColor={getColor(isDarkMode ? "light" : "button")}
              width="300"
              viewBox="0 0 300 45"
            >
              <circle r="22.5" cx="22.5" cy="22.5" />
              <rect x="60" y="12.5" rx="4" ry="4" width="200" height="20" />
            </ContentLoader>
          </div>
        ))}
    </>
  );
}
