import "react-toastify/dist/ReactToastify.css";
// Make sure it's imported immediately, before any usages
import "./hooks/useStore";
import "./initApi";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppContainer } from "./AppContainer";
import { AdyenProvider } from "./context/AdyenProvider";
import { AuthProvider } from "./context/AuthProvider";
import FireStoreProvider from "./context/FireStoreProvider";
import ReactQueryProvider from "./context/ReactQueryProvider";
import configParams from "./env";
import { ErrorBoundary } from "./initBugsnag";

console.info(`Environment: ${configParams.ENVIRONMENT}`);

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <AdyenProvider>
          <AuthProvider>
            <ReactQueryProvider>
              <FireStoreProvider>
                <AppContainer />
              </FireStoreProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </ReactQueryProvider>
          </AuthProvider>
        </AdyenProvider>
      </BrowserRouter>
      <ToastContainer autoClose={5000} theme="colored" icon={false} />
    </ErrorBoundary>
  </React.StrictMode>
);

export { useFirestore } from "./hooks/useFirestore";
