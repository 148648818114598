import React, { ComponentPropsWithoutRef, ElementType, SVGProps } from "react";

import { Loader } from "../Loader";
import { ButtonBase, ButtonInner, ButtonSize, ButtonVariant } from "./Button.style";

export interface BaseButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  Icon?: React.FC<SVGProps<SVGSVGElement>>;
  iconPosition?: "before" | "after";
  children?: React.ReactNode;
  loading?: boolean;
  as?: ElementType;
}

interface ButtonProps extends BaseButtonProps, ComponentPropsWithoutRef<"button"> {
  anchor?: false;
}

interface AnchorProps extends BaseButtonProps, React.AnchorHTMLAttributes<HTMLAnchorElement> {
  anchor: true;
}

const AnchorBase = ButtonBase.withComponent("a");

export function Button({
  as,
  size,
  variant = "default",
  children,
  loading,
  Icon,
  iconPosition = "before",
  className,
  ...rest
}: ButtonProps | AnchorProps) {
  const styleProps = {
    variant,
    size,
    loading,
  };

  const inner = (
    <ButtonInner className="btn__inner">
      {Icon && iconPosition === "before" && <Icon />}
      {loading ? <Loader variant="small" color={variant === "text" ? "blue" : "white"} /> : children}
      {Icon && iconPosition === "after" && <Icon />}
    </ButtonInner>
  );

  const classNames = [className, "btn"].filter(Boolean).join(" ");

  if (rest.anchor) {
    const { anchor: _anchor, ...attributes } = rest;

    const externalAttribues = as
      ? undefined
      : {
          target: "_blank",
          rel: "noopener noreferrer",
        };

    return (
      <AnchorBase as={as} className={classNames} {...styleProps} {...externalAttribues} {...attributes}>
        {inner}
      </AnchorBase>
    );
  }

  const { type = "button" } = rest;

  return (
    <ButtonBase
      as={as}
      className={classNames}
      {...styleProps}
      type={type}
      disabled={rest.disabled}
      {...rest}
      onClick={loading || rest.disabled ? undefined : rest.onClick}
    >
      {inner}
    </ButtonBase>
  );
}
