import { useGetUserQuery } from "@blox/api";
import { useEffect, useMemo } from "react";

import { useIsLoggedIn } from "./useIsLoggedIn";
import useScript from "./useScript";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const win = window as any;

export function useZendesk(enabled: boolean) {
  const isLoggedIn = useIsLoggedIn();

  const { data: user, isSuccess: userLoaded } = useGetUserQuery({
    query: {
      enabled: isLoggedIn,
    },
  });

  useEffect(() => {
    win.zESettings = {
      webWidget: {
        chat: {
          departments: {
            enabled: ["Bibi"],
            select: ["Bibi"],
          },
        },
      },
    };
  }, []);

  const status = useScript(
    "https://static.zdassets.com/ekr/snippet.js?key=23d053b5-4f69-4ca3-ac9e-f94582b197fe",
    { shouldPreventLoad: !enabled },
    useMemo(
      () => ({
        id: "ze-snippet",
      }),
      []
    )
  );

  useEffect(() => {
    if (status === "ready" && userLoaded) {
      win.zE?.("webWidget", "identify", {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [status, user?.email, user?.firstName, user?.lastName, userLoaded]);
}
