import { Locale } from "@blox/api";
import { Box, media, Paragraph, token } from "@blox/design-system";
import styled from "@emotion/styled";

import { useLocalisation } from "../../../context/LocalizeProvider";
import useAssumedUserCountry from "../../../hooks/useAssumedUserCountry";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { useLocale } from "../../../hooks/useStore";

const Base = styled(Box)`
  padding: 0 ${token("space.pagePaddingMobile")};
  background-color: ${token("colors.border")};

  ${media("excludePhone")} {
    padding: 0 ${token("space.pagePadding")};
  }

  a,
  p {
    font-size: 0.9rem !important;
    line-height: 1.5 !important;
  }
`;

const getRiskWarningUrl = (locale: Locale) => {
  switch (locale) {
    case "nl":
      return "https://weareblox.com/nl-be/risicowaarschuwing";
    case "fr":
      return "https://weareblox.com/fr-be/disclaimer";
    default:
      return "https://weareblox.com/en-gb/risk-warning";
  }
};

const getCryptoInfoLink = (locale: Locale) => {
  switch (locale) {
    case "fr":
    case "es":
      return "https://www.wikifin.be/fr/epargner-et-investir/produits-dinvestissement/autres-produits-dinvestissement/quest-ce-quune";
    default:
      return "https://www.wikifin.be/nl/sparen-en-beleggen/beleggingsproducten/andere-beleggingsproducten/wat-een-cryptomunt";
  }
};

function RiskBanner() {
  const locale = useLocale();
  const t = useLocalisation();
  const userCountry = useAssumedUserCountry();

  const isMobileOnly = useBreakpoint("phoneOnly");

  if (userCountry === "BE") {
    return (
      <Base>
        <Box maxWidth="78.75rem" marginHorizontal="auto">
          <Paragraph variant={["faded-blue", "small"]}>
            {isMobileOnly ? (
              <>
                {t.text("RiskBanner", "mobileContent")}{" "}
                <a target="_blank" rel="noopener noreferrer" href={getRiskWarningUrl(locale)}>
                  {t.text("RiskBanner", "mobileReadMore")}
                </a>
              </>
            ) : (
              <>
                <strong>{t.text("RiskBanner", "desktopTitle")}</strong>
                <br />
                {t.text("RiskBanner", "desktopContent", {
                  cryptoInfoLink: (
                    <a href={getCryptoInfoLink(locale)} target="_blank" rel="nofollow noopener noreferrer">
                      {t.text("RiskBanner", "cryptoInfoLinkText")}
                    </a>
                  ),
                })}
              </>
            )}
          </Paragraph>
        </Box>
      </Base>
    );
  }

  return null;
}

export default RiskBanner;
