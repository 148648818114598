import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";

import {
  AliasStyles,
  BorderStyles,
  ColorStyles,
  FlexItemStyles,
  GridItemStyles,
  LayoutStyles,
  PositionStyles,
  SpaceStyles,
  StyleProps,
  styles,
  TypographyStyles,
} from "../../styled-system/styled-system";
import { transformPropsToStyles } from "../../styled-system/transformPropsToStyles";

export type BoxProps = StyleProps<
  | LayoutStyles
  | SpaceStyles
  | BorderStyles
  | PositionStyles
  | TypographyStyles
  | ColorStyles
  | AliasStyles
  | FlexItemStyles
  | GridItemStyles
>;

export const Box = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && !styles.includes(prop),
})<BoxProps>(transformPropsToStyles);
