import styled from "@emotion/styled";

import { GridStyles, StyleProps } from "../../styled-system/styled-system";
import { Box, BoxProps } from "../Box";

export type GridProps = Omit<BoxProps, "display"> & StyleProps<GridStyles>;

export const Grid = styled(Box)<GridProps>`
  display: grid;
`;
