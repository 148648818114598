import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorModeValue, media, ThemeProps, token } from "../../tokens";

export const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.5rem;
  height: 100%;
  padding: 0 1.5rem;
  border-radius: ${token("radius.regular")};
  gap: 0.625rem;
`;

const floatingButton =
  (color: Parameters<typeof token>[0], colorDark: Parameters<typeof token>[0]) => (p: ThemeProps) => css`
    background-color: ${token(colorDark)(p)};

    > .btn__inner {
      transform: translateY(-0.3rem);
      transition: 0.2s ease all;
      background-color: ${token(color)(p)};
      pointer-events: none;
    }

    &:disabled {
      background-color: ${colorModeValue(token("colors.default"), token("colors.background"))(p)};

      > .btn__inner {
        background-color: ${token("colors.light")(p)};
        border-color: ${colorModeValue(token("colors.default"), token("colors.background"))(p)};
      }
    }

    &:not(:disabled) {
      &:hover,
      &:active {
        .btn__inner {
          transform: translateY(0);
        }
      }
    }
  `;

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "red"
  | "text"
  | "text-blue"
  | "default"
  | "white"
  | "error"
  | "flat"
  | "flat-blue"
  | "flat-grey"
  | "icon"
  | "nav"
  | "list";

export type ButtonSize = "thin" | "flex" | "default";

export const ButtonBase = styled("button", {
  shouldForwardProp: (prop) => isPropValid(prop) && !["loading", "size", "variant"].includes(prop),
})<{
  variant?: ButtonVariant;
  size?: ButtonSize;
  loading?: boolean;
}>`
  display: inline-block;
  width: 100%;
  height: 3.5rem;
  padding: 0;
  border: 0;
  border-radius: ${token("radius.regular")};
  background: transparent;
  color: ${colorModeValue(token("colors.black"), token("colors.white"))};
  font-family: ${token("fonts.viga")};
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;
  font-weight: normal;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  ${(p) => {
    switch (p.variant) {
      case "primary": {
        return floatingButton("colors.bloxYellow", "colors.bloxYellowDark")(p);
      }
      case "secondary": {
        return floatingButton("colors.bloxBlue", "colors.bloxBlueDark")(p);
      }
      case "red": {
        return css`
          ${floatingButton("colors.bloxRed", "colors.bloxRedDark")(p)};
          color: white;
        `;
      }
      case "error": {
        return css`
          ${floatingButton("colors.alert", "colors.alertDark")(p)};
          color: white;
        `;
      }
      case "flat": {
        return css`
          border: 1px solid ${token("colors.border")(p)};
          background-color: ${token("colors.white")(p)};
          color: ${token("colors.darker")(p)};

          &:hover,
          &:focus,
          &:active {
            color: ${colorModeValue(token("colors.darker"), token("colors.black"))(p)};
            border-color: ${colorModeValue(token("colors.button"), token("colors.border"))(p)};
          }

          &:active {
            color: ${colorModeValue(token("colors.darker"), token("colors.black"))(p)};
            background-color: ${token("colors.offWhite")(p)};
          }
        `;
      }
      case "flat-blue": {
        return css`
          border: 1px solid ${colorModeValue(token("colors.bloxBlueDark"), token("colors.border"))(p)};
          background-color: ${colorModeValue(token("colors.bloxBlueDark"), token("colors.offWhite"))(p)};
          color: ${token("colors.darker")(p)};

          &:hover,
          &:focus,
          &:active {
            color: ${colorModeValue(token("colors.darker"), token("colors.black"))(p)};
            border-color: ${colorModeValue(token("colors.bloxBlueDarker"), token("colors.border"))(p)};
          }

          &:active {
            color: ${colorModeValue(token("colors.darker"), token("colors.black"))(p)};
            background-color: ${colorModeValue(token("colors.bloxBlueDarker"), token("colors.offWhite"))(p)};
            border-color: ${colorModeValue(token("colors.bloxBlueDarker"), token("colors.dark"))(p)};
          }
        `;
      }
      case "flat-grey": {
        return css`
          border: 1px solid ${token("colors.border")(p)};
          background-color: ${token("colors.white")(p)};
          color: ${token("colors.dark")(p)};

          &:hover,
          &:focus,
          &:active {
            color: ${colorModeValue(token("colors.darker"), token("colors.black"))(p)};
            border-color: ${colorModeValue(token("colors.button"), token("colors.border"))(p)};
          }

          &:active {
            color: ${colorModeValue(token("colors.dark"), token("colors.black"))(p)};
            background-color: ${token("colors.offWhite")(p)};
          }
        `;
      }
      case "icon": {
        return css`
          width: auto;
          color: ${token("colors.default")(p)};

          &:hover,
          &:focus {
            color: ${token("colors.dark")(p)};
          }

          &:active {
            color: ${token("colors.darker")(p)};
          }

          > .btn__inner {
            padding: 0;
          }
        `;
      }
      case "text": {
        return css`
          width: auto;
          height: auto;
          color: inherit;

          > .btn__inner {
            padding: 0;
          }
        `;
      }
      case "text-blue": {
        return css`
          width: auto;
          height: auto;
          color: ${token("colors.bloxBlueDarker")(p)};

          &:hover,
          &:focus,
          &:active {
            color: ${colorModeValue(token("colors.bloxBlueDarkest"), token("colors.bloxBlueDark"))(p)};
          }

          > .btn__inner {
            padding: 0;
          }
        `;
      }
      case "nav": {
        return css`
          color: ${token("colors.darker")(p)};
          margin-left: -0.75rem;
          margin-right: -0.75rem;
          display: block;
          width: calc(100% + 1.5rem);

          &:hover,
          &:active {
            background-color: ${colorModeValue(token("colors.lighter"), token("colors.light"))(p)};
          }

          &:active {
            color: ${colorModeValue(token("colors.bloxBlueDarker"), token("colors.bloxBlueDark"))(p)};
          }

          &:focus,
          // NavLink active class
          &.active {
            background-color: ${colorModeValue(
              token("colors.bloxBlueLightest"),
              token("colors.bloxBlueLightest")
            )(p)};
            color: ${colorModeValue(token("colors.bloxBlueDarkest"), token("colors.bloxBlueDarkest"))(p)};
          }

          > .btn__inner {
            display: block;
            padding-left: 0.75rem;
            padding-right: 0.75rem;
          }
        `;
      }
      case "list": {
        return css`
          border: 1px solid transparent;
          background-color: ${colorModeValue(token("colors.lighter"), token("colors.light"))(p)};
          color: ${token("colors.darker")(p)};

          &:not(:disabled) {
            &:hover,
            &:focus,
            &:active {
              background-color: ${colorModeValue(token("colors.lighter"), token("colors.light"))(p)};
              color: ${token("colors.darker")(p)};
              border-color: ${colorModeValue(token("colors.button"), token("colors.default"))(p)};
            }

            &:active {
              background-color: ${token("colors.light")(p)};
              border-color: ${colorModeValue(token("colors.default"), token("colors.darker"))(p)};
            }
          }

          > .btn__inner {
            justify-content: space-between;
          }
        `;
      }
      case "white": {
        return css`
          ${floatingButton(
            p.theme.colorScheme === "dark" ? "colors.white" : "colors.white",
            p.theme.colorScheme === "dark" ? "colors.light" : "colors.grey400"
          )(p)};
          color: ${token("colors.black")(p)};

          > .btn__inner {
            border-width: 0.063rem;
            border-style: solid;
            border-color: ${token(p.theme.colorScheme === "dark" ? "colors.light" : "colors.grey400")(p)};
          }
        `;
      }
      default: {
        return css`
          ${floatingButton(
            p.theme.colorScheme === "dark" ? "colors.white" : "colors.light",
            p.theme.colorScheme === "dark" ? "colors.light" : "colors.default"
          )(p)};
          color: ${token("colors.black")(p)};

          > .btn__inner {
            border-width: 0.063rem;
            border-style: solid;
            border-color: ${token(p.theme.colorScheme === "dark" ? "colors.light" : "colors.default")(p)};
          }
        `;
        return;
      }
    }
  }}
  ${(p) => {
    switch (p.size) {
      case "thin": {
        return css`
          height: 2.25rem;
          font-size: 1rem;

          > .btn__inner {
            min-width: 2.25rem;
          }
        `;
      }
      case "flex": {
        return css`
          width: auto;
        `;
      }
    }
  }}
  ${(p) =>
    p.loading &&
    css`
      pointer-events: none;
    `}

  &:disabled {
    color: ${token("colors.default")};
    cursor: default;
  }

  ${media("excludePhone")} {
    font-size: 1.125rem;
  }
`;
