import { Theme, ThemeProvider } from "@emotion/react";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { BaseTheme, Colors, ColorScheme, lightColors } from "../tokens";

export const defaultTheme: Omit<BaseTheme, "colorScheme" | "setColorScheme"> = {
  // map all colors to css variables
  colors: Object.fromEntries(Object.keys(lightColors).map((key) => [key, `var(--color-${key})`])) as Record<
    Colors,
    string
  >,
  breakpoints: {
    excludePhone: "(min-width: 700px)",
    excludeTabletPortrait: "(min-width: 768px)",
    excludeTablet: "(min-width: 992px)",
    excludeDesktop: "(min-width: 1100px)",
    excludeDesktopLarge: "(min-width: 1200px)",
  },
  fonts: {
    viga: "var(--font-viga)",
    inter: "var(--font-inter)",
  },
  radius: {
    small: "0.25rem",
    medium: "0.5rem",
    regular: "0.75rem",
  },
};

export const COLOR_SCHEME_CLASS_PREFIX = "blox-scheme-";
export const COLOR_SCHEME_STORAGE_KEY = "blox-color-scheme";

type BloxThemeProviderProps = {
  children: ReactNode;
  darkModeEnabled?: boolean;
  forceColorScheme?: ColorScheme;
  getStoredItem?: () => ColorScheme | null;
  setStorageItem?: (colorScheme: ColorScheme) => void;
};

export function BloxThemeProvider({
  children,
  darkModeEnabled,
  forceColorScheme,
  getStoredItem = () => window.localStorage.getItem(COLOR_SCHEME_STORAGE_KEY) as ColorScheme | null,
  setStorageItem = (colorScheme) => window.localStorage.setItem(COLOR_SCHEME_STORAGE_KEY, colorScheme),
}: BloxThemeProviderProps) {
  const updateBodyClass = useCallback(
    (colorScheme: ColorScheme) => {
      const newClassName = COLOR_SCHEME_CLASS_PREFIX + (forceColorScheme ?? colorScheme);

      if (!Array.from(document.body.classList).some((className) => className === newClassName)) {
        document.body.classList.forEach((className) => {
          if (className.startsWith(COLOR_SCHEME_CLASS_PREFIX)) {
            document.body.classList.remove(className);
          }
        });
        document.body.classList.add(COLOR_SCHEME_CLASS_PREFIX + (forceColorScheme ?? colorScheme));
      }
    },
    [forceColorScheme]
  );

  const [colorScheme, setColorScheme] = useState<ColorScheme>(() => {
    if (darkModeEnabled) {
      let scheme = getStoredItem();

      if (!scheme) {
        scheme = window.matchMedia("(prefers-color-scheme: dark)") ? "dark" : "light";
      }

      return scheme;
    }

    return "light";
  });

  useEffect(() => {
    if (darkModeEnabled) {
      setStorageItem(colorScheme);
      updateBodyClass(colorScheme);
    }
  }, [colorScheme, darkModeEnabled, setStorageItem, updateBodyClass]);

  return (
    <ThemeProvider
      theme={
        {
          colorScheme: forceColorScheme ?? colorScheme,
          setColorScheme,
          ...defaultTheme,
        } as Theme
      }
    >
      {children}
    </ThemeProvider>
  );
}
