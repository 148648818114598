import { useGetUserInfoQuery } from "@blox/api";
import { selectUserId } from "@blox/shared/selectors/userSelectors";
import { useTheme } from "@emotion/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import TagManager from "react-gtm-module";

import configParams from "../env";
import { useIsLoggedIn } from "./useIsLoggedIn";

declare global {
  interface Window {
    dataLayer?: {
      push: (event: GTMEvent) => boolean;
    };
  }
}

export function useTagManager(enabled: boolean) {
  const isInitialized = useRef(false);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: configParams["GTM-ID"],
    };

    if (enabled && !isInitialized.current) {
      isInitialized.current = true;
      TagManager.initialize(tagManagerArgs);
    }
  }, [enabled]);
}

type GTMEvent = {
  event: string;
  event_name: string;
  event_category: string;
  event_action: string;
} & Record<string, string>;

export function useGTMEvent() {
  const theme = useTheme().colorScheme;

  const { data: userId } = useGetUserInfoQuery({
    query: {
      select: selectUserId,
      enabled: useIsLoggedIn(),
    },
  });

  const sendGTMEvent = useCallback(
    (event: GTMEvent) => {
      const eventWithMetadata = {
        ...event,
        theme,
        pageUrl: window.location.href,
        userId: userId ?? "unknown",
      };

      if (configParams.ENVIRONMENT !== "PRODUCTION") {
        console.info("GTM Event", eventWithMetadata);
      }
      window.dataLayer?.push(eventWithMetadata);
    },
    [theme, userId]
  );

  return useMemo(() => ({ sendGTMEvent }), [sendGTMEvent]);
}
