import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ComponentPropsWithoutRef, ElementType } from "react";

import { media, ThemeProps, token } from "../../tokens";

const headingStyle = (p: ThemeProps & Omit<HeadingProps, "level">) => css`
  font-family: ${token("fonts.viga")(p)};
  font-weight: normal;
  color: ${token("colors.black")(p)};

  ${p.textAlign &&
  css`
    text-align: ${p.textAlign};
  `};
`;

const H1 = styled.h1<Omit<HeadingProps, "level">>`
  ${headingStyle};
  font-size: 2.25rem;

  ${media("excludePhone")} {
    font-size: 3rem;
  }
`;

const H2 = styled.h2<Omit<HeadingProps, "level">>`
  ${headingStyle};
  font-size: 1.5rem;

  ${media("excludePhone")} {
    font-size: 2.5rem;
  }
`;

const H3 = styled.h3<Omit<HeadingProps, "level">>`
  ${headingStyle};
  font-size: 1.3125rem;

  ${media("excludePhone")} {
    font-size: 1.5rem;
  }
`;

const H4 = styled.h4<Omit<HeadingProps, "level">>`
  ${headingStyle};
  font-size: 1.25rem;

  ${media("excludePhone")} {
    font-size: 1.25rem;
  }
`;

const H5 = styled.h5<Omit<HeadingProps, "level">>`
  ${headingStyle};
  font-size: 1.0625rem;
`;

const H6 = styled.h6<Omit<HeadingProps, "level">>`
  ${headingStyle};
`;

export type HeadingProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  textAlign?: "left" | "center" | "right";
  as?: ElementType;
} & ComponentPropsWithoutRef<"h1" | "h2" | "h3" | "h4" | "h5" | "h6">;
export const Heading = ({ children, level, ...rest }: HeadingProps) => {
  const Component = (
    {
      1: H1,
      2: H2,
      3: H3,
      4: H4,
      5: H5,
      6: H6,
    } as const
  )[level];

  return <Component {...rest}>{children}</Component>;
};
