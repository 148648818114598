import { Locale } from "@blox/api";
import { COLOR_SCHEME_STORAGE_KEY, ColorScheme } from "@blox/design-system";
import { produce } from "immer";
import { create, StoreApi } from "zustand";
import { persist } from "zustand/middleware";

import { defaultLocale, isSupportedLocale } from "../context/LocalizeProvider";

export type StoreState = {
  userLocale: Locale;
  referralData: { code: string; timestamp: number } | null;
  riskWarningTimeStamp: number | null;
  seenBalanceEmptyMarket: boolean;
  enableProGraph: boolean;
  colorScheme: ColorScheme | null;
  hideVaultValues: boolean;
  showNews: boolean;
  showPortfolioHistory: boolean;
};

type StoreActions = {
  mutateStore: (fn: (state: Store) => void) => void;
  setStore: StoreApi<Store>["setState"];
};

export type Store = StoreState & StoreActions;

function safelyGetLegacyStorageItem<T>(key: string) {
  const value = window.localStorage.getItem(key);

  if (value == null) {
    return value;
  }

  window.localStorage.removeItem(key);

  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return value as T;
  }
}

// this can be mocked in Playwright via `session.mockStore()`
const storeMock = window?.sessionStorage.getItem("storeMock")
  ? (JSON.parse(window.sessionStorage.getItem("storeMock")!) as Partial<StoreState>)
  : undefined;

const userLocale = safelyGetLegacyStorageItem<Locale>("userLocale") ?? defaultLocale;

const initialState: StoreState = {
  userLocale,
  referralData: safelyGetLegacyStorageItem("referralCode") ?? null,
  riskWarningTimeStamp: safelyGetLegacyStorageItem("riskWarningTimeStamp") ?? null,
  seenBalanceEmptyMarket: safelyGetLegacyStorageItem("seenBalanceEmptyMarket") ?? false,
  enableProGraph: safelyGetLegacyStorageItem("proGraphPreference") ?? false,
  colorScheme: safelyGetLegacyStorageItem(COLOR_SCHEME_STORAGE_KEY) ?? null,
  hideVaultValues: false,
  showNews: userLocale === "nl",
  showPortfolioHistory: true,
};

export const useStore = create<Store>()(
  persist(
    (set) => ({
      mutateStore: (fn) => set(produce(fn)),
      setStore: set,
      ...initialState,
      ...storeMock,
    }),
    {
      name: "blox-config",
      version: 2,
      migrate: (persistedState) => {
        if (!isSupportedLocale((persistedState as Store).userLocale)) {
          (persistedState as Store).userLocale = defaultLocale;
        }
        return persistedState as Store;
      },
    }
  )
);

export const useMutateStore = () => {
  return useStore((store) => store.mutateStore);
};

export function useLocale() {
  return useStore((s) => s.userLocale);
}
