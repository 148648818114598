import "./Modal.scss";

import { Box, Button, Heading, Loader, Paragraph } from "@blox/design-system";
import { SvgCloseSmall } from "@blox/icons";
import classNames from "classnames";
import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import Div100vh from "react-div-100vh";

import backIcon from "../../../assets/img/back-icon.svg";
import { ModalVariant, useCurrentModal } from "../../../context/ModalProvider";
import Skeleton from "../../Skeleton/Skeleton";
import { Suspender } from "../../Suspender/Suspender";

const Modal = React.forwardRef<HTMLDivElement, ComponentPropsWithoutRef<"div"> & { variant?: ModalVariant }>(
  function Modal({ className, variant, ...rest }, ref) {
    const { isClosing, isOpening, closeModal, modalOptions, modalContent } = useCurrentModal();
    const classes = classNames(className, {
      modal: true,
      "modal--closing": isClosing && !isOpening,
      "modal--wide": modalOptions.wide,
      "modal--fullscreen-mobile": modalOptions.fullscreenOnMobile,
      [`modal--variant-${variant}`]: true,
    });

    return (
      <Div100vh className={classes} data-testid="modal" ref={ref} {...rest}>
        <div className="modal__window">
          {modalOptions.headerTitle && <ModalHeader title={modalOptions.headerTitle} />}
          <div className="modal__content-wrapper">
            <div className="modal__content">
              <Suspender
                fallback={
                  <Box padding={40}>
                    <Skeleton variant="paper" />
                  </Box>
                }
              >
                {modalContent}
              </Suspender>
            </div>
            {!modalOptions.noCancelButton && (
              <div className="modal__cancel">
                <Button variant="text" onClick={closeModal}>
                  {modalOptions.cancelButtonText}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="modal__backdrop" onClick={closeModal}></div>
      </Div100vh>
    );
  }
);

export default Modal;

export function ModalHeader({
  title,
  backButtonEvent,
  showCloseButton = true,
}: {
  title: string;
  backButtonEvent?: () => void;
  showCloseButton?: boolean;
}) {
  const { closeModal } = useCurrentModal();
  return (
    <div className="modal__header" data-testid="modal-header">
      {backButtonEvent ? (
        <button onClick={backButtonEvent} data-testid="modal-back">
          <img src={backIcon} alt="back-icon" />
        </button>
      ) : (
        <div style={{ width: "2.5rem" }} />
      )}
      <Heading level={4}>{title}</Heading>
      {showCloseButton && (
        <Button variant="icon" onClick={closeModal} data-testid="modal-close">
          <SvgCloseSmall />
        </Button>
      )}
    </div>
  );
}

export function ModalContent({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={classNames("modal__inner-content", className)} data-testid="modal-content">
      {children}
    </div>
  );
}

export function ModalSummaryAmount({
  Icon,
  mainText,
  subText,
  loading,
}: {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  mainText?: string;
  subText?: string;
  loading?: boolean;
}) {
  return (
    <div className="modal__summery-amount" data-testid="modal-summary-amount">
      <Icon />
      {loading ? (
        <div className="modal__summery-loader">
          <Loader variant="small" />
        </div>
      ) : (
        <Paragraph className="modal__summery-maintext">{mainText}</Paragraph>
      )}
      {subText && <Paragraph className="modal__summery-subtext">{subText}</Paragraph>}
    </div>
  );
}

export function ModalSummaryItems({ children }: { children: React.ReactNode }) {
  return (
    <div className="modal__summery-items" data-testid="modal-summary-items">
      {children}
    </div>
  );
}

export type HighLightType = "success" | "error" | "warning" | "pending";

export function ModalSummaryItem({
  leftText,
  rightText,
  loading,
  highLight,
  description,
  ...rest
}: {
  leftText?: ReactNode;
  rightText?: ReactNode;
  loading?: boolean;
  highLight?: HighLightType;
  description?: string;
} & ComponentPropsWithoutRef<"div">) {
  const classes = classNames({
    "modal__summery-item-right-text": true,
    "modal__summery-item-right-text--bubble": highLight,
    [`modal__summery-item-right-text--${highLight}`]: !!highLight,
  });

  return (
    <div className="modal__summery-item" {...rest}>
      <div className="modal__summery-item-inner">
        <Paragraph className="modal__summery-item-left-text">{leftText}</Paragraph>
        {loading ? <Loader variant="small" /> : <Paragraph className={classes}>{rightText}</Paragraph>}
      </div>
      {description && <Paragraph>{description}</Paragraph>}
    </div>
  );
}
