import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import Modal from "../components/UI/Modal/Modal";
import { useBreakpoint } from "../hooks/useBreakpoint";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { useLocalisation } from "./LocalizeProvider";

export type ModalVariant = "default" | "blue" | "gradient";

export type ModalOptionsType = {
  headerTitle?: string;
  cancelButtonText?: string;
  noCancelButton?: boolean;
  wide?: boolean;
  onClose?: () => void;
  fullscreenOnMobile?: boolean;
  variant?: ModalVariant;
  method?: "replace" | "push";
};

type ModalContextType = {
  openModal: (content: JSX.Element, options?: ModalOptionsType) => void;
};

type ModalStackItem = {
  modalOptions: ModalOptionsType;
  modalContent: ReactNode;
  isClosing: boolean;
  isOpening: boolean;
};

type CurrentModalContextType = ModalStackItem & {
  closeModal: () => void;
};

const ModalContext = React.createContext<ModalContextType>(null!);
const CurrentModalContext = React.createContext<CurrentModalContextType>(null!);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const t = useLocalisation();
  const isAuthenticated = useIsLoggedIn();

  const defaultOptions: ModalOptionsType = useMemo(
    () => ({
      cancelButtonText: t.text("Modal", "cancelButtonText"),
      fullscreenOnMobile: true,
      method: "replace",
    }),
    [t]
  );

  const scrollTop = useRef(0);

  const [modalStack, setModalStack] = useState<ModalStackItem[]>([]);

  const excludePhone = useBreakpoint("excludePhone");

  const openModal = useCallback(
    (content: JSX.Element, options?: ModalOptionsType) => {
      const item: ModalStackItem = {
        modalOptions: {
          ...defaultOptions,
          ...options,
        },
        modalContent: content ?? null,
        isOpening: true,
        isClosing: false,
      };
      if (options?.method === "push") {
        setModalStack((stack) => [...stack, item]);
      } else {
        // if the previous modal is already open we shouldn't set isOpening to true
        // but instead let it take the the state from the previous modal item
        // otherwise the onAnimationEnd event will not get called
        setModalStack((stack) => [{ ...item, isOpening: stack[0]?.isOpening ?? true }]);
      }
    },
    [defaultOptions]
  );

  const closeModal = useCallback((index: number) => {
    return () => {
      setModalStack((stack) =>
        stack.map((item, itemIndex) => (index === itemIndex ? { ...item, isClosing: true } : item))
      );
    };
  }, []);

  useEffect(() => {
    if (!excludePhone) {
      if (modalStack.length > 0) {
        scrollTop.current = window.scrollY;
        document.body.style.top = `${window.scrollY * -1}px`;
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
        document.body.style.top = "";
        window.scroll({ top: scrollTop.current });
      }
    }
  }, [excludePhone, modalStack.length]);

  useEffect(() => {
    // if user is logged out of keyCloak, automatically close any modal screen
    if (!isAuthenticated) {
      setModalStack([]);
    }
  }, [isAuthenticated]);

  return (
    <ModalContext.Provider value={{ openModal }}>
      {modalStack.map(({ modalContent, modalOptions, isClosing, isOpening }, index) => (
        <CurrentModalContext.Provider
          key={index}
          value={{ closeModal: closeModal(index), isClosing, isOpening, modalContent, modalOptions }}
        >
          <Modal
            variant={modalOptions.variant}
            onAnimationEnd={() => {
              if (isOpening) {
                setModalStack((stack) =>
                  stack.map((item, itemIndex) => (index === itemIndex ? { ...item, isOpening: false } : item))
                );
              }
            }}
            onTransitionEnd={() => {
              if (isClosing) {
                setModalStack(modalStack.filter((_, itemIndex) => index !== itemIndex));
                modalOptions.onClose?.();
              }
            }}
          />
        </CurrentModalContext.Provider>
      ))}

      {children}
    </ModalContext.Provider>
  );
};

export function useCurrentModal() {
  const modalContext = useContext(CurrentModalContext);

  if (!modalContext) {
    throw new Error("Make sure you only call useCurrentModal inside an active Modal component");
  }

  return modalContext;
}

export function useModal() {
  return useContext(ModalContext);
}
