import styled from "@emotion/styled";
import { Children, ReactElement, ReactNode } from "react";
import flattenChildren from "react-keyed-flatten-children";

import { PropValue } from "../../styled-system/styled-system";
import { Box } from "../Box";
import { Flex, FlexProps } from "../Flex";

const Item = styled(Box)`
  width: 100%;

  &:empty,
  &:last-of-type {
    padding-bottom: 0;
  }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export type StackProps = FlexProps & {
  children: ReactNode;
  space: PropValue;
};

export const Stack = ({ children, space, ...rest }: StackProps) => {
  const flattenedChilderen = flattenChildren(children);

  if (flattenedChilderen.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" {...rest}>
      {Children.map(flattenedChilderen, (child, index) => {
        const key = typeof (child as ReactElement).key !== "undefined" ? (child as ReactElement).key : index;

        return (
          <Item key={key} paddingBottom={space}>
            {child}
          </Item>
        );
      })}
    </Flex>
  );
};
