import styled from "@emotion/styled";
import { ComponentPropsWithoutRef, ReactNode } from "react";

import { Heading } from "../Heading";
import { PaperBase, PaperContent, PaperTitle } from "./Paper.style";

const StyledHeading = styled(Heading)`
  margin-block: 1.25rem;
`;

export type PaperProps = {
  title?: string | ReactNode;
  contentPadding?: boolean;
  contentPaddingHorizontal?: boolean;
  contentPaddingVertical?: boolean;
} & Omit<ComponentPropsWithoutRef<"div">, "title">;

export const Paper = ({
  title,
  contentPadding,
  contentPaddingHorizontal,
  contentPaddingVertical,
  children,
  className,
  ...rest
}: PaperProps) => {
  return (
    <PaperBase {...rest}>
      {title && (
        <PaperTitle>
          {typeof title === "string" ? <StyledHeading level={3}>{title}</StyledHeading> : title}
        </PaperTitle>
      )}
      <PaperContent
        padding={contentPadding ? { base: "1.25rem", excludePhone: "2rem" } : undefined}
        paddingHorizontal={contentPaddingHorizontal ? { base: "1.25rem", excludePhone: "2rem" } : undefined}
        paddingVertical={contentPaddingVertical ? { base: "1.25rem", excludePhone: "2rem" } : undefined}
        className={className}
      >
        {children}
      </PaperContent>
    </PaperBase>
  );
};
