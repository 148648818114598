import { useEffect } from "react";

import useScript from "./useScript";

const GA_ID = "G-2Q6Z9YKYG2";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const win = window as any;

win.dataLayer = win.dataLayer || [];
win.gtag = function () {
  // eslint-disable-next-line prefer-rest-params
  win.dataLayer.push(arguments);
};

export function useGoogleAnalytics(enabled: boolean) {
  const status = useScript(`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`, {
    shouldPreventLoad: !enabled,
  });

  useEffect(() => {
    if (status === "ready") {
      win.gtag("js", new Date());
      win.gtag("config", GA_ID);
      win.gtag("consent", "update", { ad_storage: "granted", analytics_storage: "granted" });
    }
  }, [status]);
}

export function analyticsEvent(event: string, params?: Record<string, unknown>) {
  if (win.gtag) {
    win.gtag("event", event, params);
  }

  if (import.meta.env.MODE === "development") {
    console.info("[ANALYTICS]", event, params);
  }
}
