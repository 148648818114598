import { Theme } from "@emotion/react";
import { CSSProperties } from "react";

export const convertToRem = (value: number) => (value ? `${value / 16}rem` : value);
export const convertToPercentage = (value: number) => `${value * 100}%`;

type Breakpoints = keyof Theme["breakpoints"];
type PseudoClasses = "hover" | "focus" | "active" | "focus-within" | "disabled" | "checked";

type PropObjectKeys = "base" | Breakpoints | PseudoClasses;
export type PrimitiveValue = string | number;

export type PropValue<V = PrimitiveValue> = V | { [key in PropObjectKeys]?: PropValue<V> };
export type StyleProps<T extends CSSProperties | AliasStyles> = {
  [key in keyof T]: PropValue<T[key]>;
};

export const layoutProps = [
  "width",
  "height",
  "minWidth",
  "maxWidth",
  "minHeight",
  "maxHeight",
  "display",
  "verticalAlign",
  "overflow",
] as const;
export type LayoutPropKeys = (typeof layoutProps)[number];
export type LayoutStyles = Pick<CSSProperties, LayoutPropKeys>;

export const spaceProps = [
  "margin",
  "marginTop",
  "marginRight",
  "marginBottom",
  "marginLeft",
  "padding",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
] as const;
export type SpacePropKeys = (typeof spaceProps)[number];
export type SpaceStyles = Pick<CSSProperties, SpacePropKeys>;

export const borderProps = [
  "border",
  "borderWidth",
  "borderStyle",
  "borderRadius",
  "borderTop",
  "borderTopWidth",
  "borderTopStyle",
  "borderTopLeftRadius",
  "borderTopRightRadius",
  "borderRight",
  "borderRightWidth",
  "borderRightStyle",
  "borderBottom",
  "borderBottomWidth",
  "borderBottomStyle",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",
  "borderLeft",
  "borderLeftWidth",
  "borderLeftStyle",
] as const;
export type BorderPropKeys = (typeof borderProps)[number];
export type BorderStyles = Pick<CSSProperties, BorderPropKeys>;

export const flexProps = [
  "alignItems",
  "alignContent",
  "justifyItems",
  "justifyContent",
  "flexWrap",
  "flexDirection",
  "gap",
] as const;
export type FlexPropKeys = (typeof flexProps)[number];
export type FlexStyles = Pick<CSSProperties, FlexPropKeys>;

export const flexItemProps = [
  "flex",
  "flexGrow",
  "flexShrink",
  "flexBasis",
  "justifySelf",
  "alignSelf",
  "order",
] as const;
export type FlexItemPropKeys = (typeof flexItemProps)[number];
export type FlexItemStyles = Pick<CSSProperties, FlexItemPropKeys>;

export const gridProps = [
  "gap",
  "gridColumnGap",
  "gridRowGap",
  "gridAutoFlow",
  "gridAutoColumns",
  "gridAutoRows",
  "gridTemplateColumns",
  "gridTemplateRows",
  "gridTemplateAreas",
  "alignItems",
  "alignContent",
  "justifyItems",
  "justifyContent",
] as const;
export type GridPropKeys = (typeof gridProps)[number];
export type GridStyles = Pick<CSSProperties, GridPropKeys>;

export const gridItemProps = ["gridColumn", "gridRow", "gridArea"] as const;
export type GridItemPropKeys = (typeof gridItemProps)[number];
export type GridItemStyles = Pick<CSSProperties, GridItemPropKeys>;

export const positionLocationProps = ["top", "right", "bottom", "left"] as const;
export const positionProps = ["position", "zIndex", ...positionLocationProps] as const;
export type PositionPropKeys = (typeof positionProps)[number];
export type PositionStyles = Pick<CSSProperties, PositionPropKeys>;

export const typographyProps = [
  "fontFamily",
  "fontSize",
  "fontWeight",
  "lineHeight",
  "letterSpacing",
  "textAlign",
  "fontStyle",
] as const;
export type TypographyPropKeys = (typeof typographyProps)[number];
export type TypographyStyles = Omit<Pick<CSSProperties, TypographyPropKeys>, "fontFamily"> & {
  fontFamily: keyof Theme["fonts"];
};

type MapThemeColor<T extends CSSProperties> = {
  [key in keyof T]: keyof Theme["colors"] | T[key];
};
export const colorProps = [
  "color",
  "backgroundColor",
  "borderColor",
  "borderTopColor",
  "borderRightColor",
  "borderBottomColor",
  "borderLeftColor",
] as const;
export type ColorPropKeys = (typeof colorProps)[number];
export type ColorStyles = MapThemeColor<Pick<CSSProperties, ColorPropKeys>>;

export const aliasProps = {
  paddingHorizontal: ["paddingLeft", "paddingRight"],
  paddingVertical: ["paddingTop", "paddingBottom"],
  marginHorizontal: ["marginLeft", "marginRight"],
  marginVertical: ["marginTop", "marginBottom"],
} as const;
export type AliasPropKeys = keyof typeof aliasProps;
export type AliasStyles = Partial<{
  [key in AliasPropKeys]: CSSProperties[(typeof aliasProps)[key][0]];
}>;

export type AllStyles =
  | LayoutStyles
  | SpaceStyles
  | BorderStyles
  | FlexStyles
  | FlexItemStyles
  | GridStyles
  | GridItemStyles
  | PositionStyles
  | TypographyStyles
  | ColorStyles
  | AliasStyles;

export type PropKey = keyof AllStyles;

export const styles = [
  ...layoutProps,
  ...spaceProps,
  ...borderProps,
  ...flexProps,
  ...flexItemProps,
  ...gridProps,
  ...gridItemProps,
  ...positionProps,
  ...typographyProps,
  ...colorProps,
  ...Object.keys(aliasProps),
];
