import { token } from "@blox/design-system";
import styled from "@emotion/styled";

export const ImageWrapper = styled.div<{ size?: "rect" | "square" }>`
  position: relative;
  padding-bottom: ${(p) => (p.size === "square" ? "100%" : "56.25%")};

  > img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: ${token("radius.regular")};
    object-fit: cover;
    object-position: center center;
  }
`;

export const PaymentMethods = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
`;
