export const lightColors = {
  black: "#090a0c",
  darker: "#284964",
  dark: "#88939d",
  default: "#a2b5ca",
  light: "#f1f5fa",
  lighter: "#F8FBFF",
  offWhite: "#f8fbff",
  grey200: "#F1F5F9",
  grey400: "#D5DDE5",
  grey900: "#070C18",
  button: "#d9e0e7",
  white: "white",
  background: "#F8FBFF",
  border: "#e8edf3",

  bloxBlueDarkest: "#008bad",
  bloxBlueDarker: "#25b1d3",
  bloxBlueDark: "#59cbe7",
  bloxBlue: "#78d7f0",
  bloxBlueLight: "#95e6fb",
  bloxBlueLighter: "#b7efff",
  bloxBlueLightest: "#d8f4fa",

  bloxYellowDarkest: "#c9a900",
  bloxYellowDarker: "#edc700",
  bloxYellowDark: "#ffd600",
  bloxYellow: "#ffeb14",
  bloxYellowLight: "#fff376",
  bloxYellowLighter: "#fff9ba",

  bloxRedDarker: "#af262b",
  bloxRedDark: "#c3373c",
  bloxRed: "#e15055",
  bloxRedLight: "#f87479",
  bloxRedLighter: "#ffb1b4",
  bloxRedLightest: "#f5dcdc",

  successDark: "#00966D",
  success: "#00BA88",
  successLight: "#B4FDBB",
  successLighter: "#E2F7F1",

  warningDark: "#de9300",
  warning: "#ffa800",
  warningLight: "#ffe7b8",
  warningLighter: "#fff1d7",

  alertDark: "#DA341D",
  alert: "#FF3C25",
  alertLight: "#FE7160",
  alertLighter: "#FDA09A",
  alertLightest: "#FFE9E8",

  yellowDark: "#d9b500",
  yellow: "#ffd70f",
  yellowLight: "#fff0a3",

  greenDark: "#169999",
  green: "#3cc8c8",
  greenLight: "#82f4f4",

  purpleDark: "#922785",
  purple: "#eb70dc",
  purpleLight: "#eeade6",

  pinkDark: "#cf3a54",
  pink: "#fb5e7a",
  pinkLight: "#ffbcc8",
} as const;
