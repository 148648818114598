import { colorModeValue, media, token } from "@blox/design-system";
import styled from "@emotion/styled";

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppContent = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${token("space.pagePaddingMobile")} 3.125rem;
  background: ${colorModeValue("none", token("colors.white"))};

  ${media("excludePhone")} {
    padding: 0 ${token("space.pagePadding")};
    background-color: ${token("colors.background")};
  }
`;

export const AppMain = styled.div`
  width: 100%;
  min-height: 100vh;

  ${media("excludeTablet")} {
    padding-left: ${token("space.pagePadding")};
  }
`;

export const AppPage = styled.div`
  max-width: ${token("space.containerWidth")};
  margin: 0 auto;
`;
