import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colorModeValue, darkMode, token } from "../../tokens";

export type InnerLabelStyle = "default" | "icon";
export type InnerLabelPosition = "left" | "right";

export const InputInnerLabel = styled.div<{
  innerLabelPosition?: InnerLabelPosition;
  innerLabelStyle?: InnerLabelStyle;
  error?: boolean;
}>`
  display: flex;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  align-items: center;
  justify-content: center;
  width: 4.375rem;
  border-right: 1px solid ${token("colors.light")};
  border-top-left-radius: ${token("radius.regular")};
  border-bottom-left-radius: ${token("radius.regular")};
  background-color: ${colorModeValue(token("colors.offWhite"), token("colors.background"))};
  color: ${colorModeValue(token("colors.dark"), token("colors.black"))};
  pointer-events: none;

  ${(p) =>
    p.innerLabelPosition === "right" &&
    css`
      left: auto;
      right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: ${token("radius.regular")(p)};
      border-bottom-right-radius: ${token("radius.regular")(p)};
    `}

  ${(p) =>
    p.innerLabelStyle === "icon" &&
    css`
      background: none;
      width: 3.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-right: 0;
    `}

  ${(p) =>
    p.error &&
    css`
      border-color: ${token("colors.alertLighter")(p)};
      background-color: ${token("colors.alertLightest")(p)};
      color: ${token("colors.alert")(p)};
    `}
`;

export const InputWrapper = styled.div<{
  innerLabel?: boolean;
  innerLabelStyle?: InnerLabelStyle;
  innerLabelPosition?: InnerLabelPosition;
  error?: boolean;
}>`
  width: 100%;

  input,
  select,
  textarea {
    font-family: ${token("fonts.inter")};
    color: ${token("colors.darker")};
    font-size: 1.0625rem;

    &:disabled {
      color: ${token("colors.default")};
    }

    ${darkMode()} {
      background-color: ${token("colors.light")};
    }
  }

  textarea {
    resize: vertical;
  }

  input:not([type="checkbox"]),
  textarea,
  select {
    width: 100%;
    padding: 1rem;
    border: 1px solid ${token("colors.light")};
    border-radius: ${token("radius.regular")};
    outline-color: ${colorModeValue(token("colors.bloxBlueDark"), token("colors.darker"))};

    &:focus {
      outline-style: solid;
      outline-width: 1px;
    }
  }

  select {
    position: relative;
    padding-right: 2rem;
    -webkit-appearance: none;
    appearance: none;
  }

  label {
    display: block;
    margin-bottom: 0.625rem;
    color: ${token("colors.dark")};
    font-size: 1rem;
    font-weight: 600;
  }

  ${(p) =>
    p.innerLabel &&
    css`
      position: relative;

      input:not([type="checkbox"]) {
        padding-${p.innerLabelPosition ?? "left"}: ${p.innerLabelStyle === "icon" ? "3.5rem" : "5.3125rem"};
      }
    `}

  ${(p) =>
    p.error &&
    css`
      input:not([type="checkbox"]),
      textarea,
      select {
        border-color: ${token("colors.alert")(p)};
      }
    `}
`;

export const InputWrapperInput = styled.div`
  position: relative;
  z-index: 1;
`;
