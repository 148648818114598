import styled from "@emotion/styled";

import { contentBox } from "../../mixins";
import { media, token } from "../../tokens";
import { Box } from "../Box";

export const PaperBase = styled.div`
  ${contentBox};

  margin-left: -1.25rem;
  margin-right: -1.25rem;

  ${media("excludePhone")} {
    margin-left: initial;
    margin-right: initial;
  }
`;

export const PaperTitle = styled.div`
  font-family: ${token("fonts.viga")};
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: 1.25rem;
  padding-left: 1.25rem;
  border-bottom: 1px solid ${token("colors.border")};

  ${media("excludePhone")} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export const PaperContent = styled(Box)`
  > p:first-of-type {
    margin-top: 0;
  }

  > p:last-child {
    margin-bottom: 0;
  }
`;
