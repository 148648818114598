import { css } from "@emotion/react";

import { media, ThemeProps, token } from "../tokens";

export const contentBox = (p: ThemeProps) => css`
  border: 0;
  border-radius: 0;
  background: none;

  ${media("excludePhone")(p)} {
    border: 1px solid ${token("colors.border")(p)};
    border-radius: ${token("radius.regular")(p)};
    background-color: ${token("colors.white")(p)};
  }
`;
export const buttonReset = css`
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
`;

export const textLink = (p: ThemeProps) => css`
  ${buttonReset};

  display: inline;
  width: auto;
  color: ${token("colors.bloxBlueDarker")(p)};
  cursor: pointer;
  font-weight: 500;

  &:hover,
  &:focus {
    color: ${token("colors.bloxBlueDarker")(p)};
    text-decoration: underline;
  }

  &:active {
    color: ${token("colors.bloxBlueDarkest")(p)};
  }
`;
