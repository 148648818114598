import { colorModeValue, contentBox, getColorVars, media, token } from "@blox/design-system";
import { css, Global } from "@emotion/react";

export const GlobalStyle = () => {
  return (
    <Global
      styles={(theme) => {
        const p = { theme };

        return css`
          :root {
            ${getColorVars(theme.colorScheme)};

            --font-viga: Viga, sans-serif;
            --font-inter: Inter, sans-serif;

            --toastify-color-success: var(--color-success);
            --toastify-color-warning: var(--color-warning);
            --toastify-color-error: var(--color-alert);
            --toastify-color-info: var(--color-bloxBlueDark);
          }

          html {
            box-sizing: border-box;
            text-rendering: optimizelegibility;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            /* stylelint-disable */
            font-size: 16px;

            @media (min-width: 1920px) {
              font-size: min(calc(16px + (100vw - 1920px) * 0.005), 18px);
            }
            /* stylelint-enable */
          }

          *,
          *::before,
          *::after {
            box-sizing: inherit;
          }

          html,
          body,
          #root {
            margin: 0;
            padding: 0;
            color: ${token("colors.darker")(p)};
            font-family: ${token("fonts.inter")(p)};
            font-weight: normal;
          }

          body {
            &.blox-scheme-dark {
              background-color: ${token("colors.background")(p)};
            }

            &.modal-open {
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              overflow-y: scroll;
            }
          }

          .adyen-checkout__dropin {
            color: ${colorModeValue("inherit", token("colors.light"))(p)};
          }

          input,
          textarea {
            color: inherit;
          }

          a,
          li,
          button {
            font-size: 1.0625rem;
          }

          a {
            color: ${token("colors.bloxBlueDark")(p)};
            text-decoration: none;
          }

          p {
            margin: 1rem 0;
          }

          svg {
            flex-shrink: 0;
          }

          img {
            max-width: 100%;
            height: auto;
          }

          h2 {
            margin: 2rem 0 1.25rem;
          }

          // Helper classes
          .pointer {
            cursor: pointer;
          }

          .centered-text {
            text-align: center;
          }

          .relative {
            position: relative;
          }

          .mobile-only {
            ${media("excludePhone")(p)} {
              display: none;
            }
          }

          .hide-on-mobile {
            ${media("excludePhone", true)(p)} {
              display: none !important;
            }
          }

          .hide-on-tablet {
            ${media("excludeTablet", true)(p)} {
              display: none !important;
            }
          }

          .content-box {
            ${contentBox(p)}
          }

          .padding-horizontal {
            padding-right: ${token("space.pagePaddingMobile")(p)};
            padding-left: ${token("space.pagePaddingMobile")(p)};
          }

          .padding-vertial {
            padding-top: ${token("space.pagePaddingMobile")(p)};
            padding-bottom: ${token("space.pagePaddingMobile")(p)};
          }

          .padding {
            padding: ${token("space.pagePaddingMobile")(p)};
          }

          .padding-left {
            padding-left: ${token("space.pagePaddingMobile")(p)};
          }

          .padding-right {
            padding-right: ${token("space.pagePaddingMobile")(p)};
          }

          .padding-top {
            padding-top: ${token("space.pagePaddingMobile")(p)};
          }

          .padding-bottom {
            padding-bottom: ${token("space.pagePaddingMobile")(p)};
          }

          ${media("excludePhone")(p)} {
            .padding-horizontal {
              padding-right: ${token("space.pagePadding")(p)};
              padding-left: ${token("space.pagePadding")(p)};
            }

            .padding-vertial {
              padding-top: ${token("space.pagePadding")(p)};
              padding-bottom: ${token("space.pagePadding")(p)};
            }

            .padding {
              padding: ${token("space.pagePadding")(p)};
            }

            .padding-left {
              padding-left: ${token("space.pagePadding")(p)};
            }

            .padding-right {
              padding-right: ${token("space.pagePadding")(p)};
            }

            .padding-top {
              padding-top: ${token("space.pagePadding")(p)};
            }

            .padding-bottom {
              padding-bottom: ${token("space.pagePadding")(p)};
            }
          }

          .modal-open #launcher {
            display: none !important;
          }
          ${media("excludePhone", true)(p)} {
            #launcher {
              display: none !important;
            }
          }
        `;
      }}
    />
  );
};
