import { defaultTheme } from "@blox/design-system";
import { Theme } from "@emotion/react";

export const themeOverrides: Partial<Theme> = {
  breakpoints: {
    ...defaultTheme.breakpoints,
    /**
     * @deprecated use mobile first approach, use excludePhone instead
     */
    phoneOnly: "(max-width: 699px)",
  },
  space: {
    pagePadding: "2rem",
    pagePaddingMobile: "1.25rem",
    containerWidth: "78.75rem",
  },
};
