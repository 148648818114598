import { ComponentPropsWithoutRef, ReactNode } from "react";

import { BoxProps } from "../Box";
import { ToggleButtonBase, ToggleButtonOption } from "./ToggleButton.style";

export type ToggleButtonProps<V> = Omit<ComponentPropsWithoutRef<"div">, "value" | "onChange"> &
  BoxProps & {
    value: V;
    onChange: (value: V) => void;
    options: {
      label: ReactNode;
      value: V;
    }[];
    stretch?: boolean;
    border?: boolean;
    matchFn?: (optionValue: V, currentValue: V) => boolean;
  };

export function ToggleButton<V extends string | number>({
  value,
  onChange,
  options,
  stretch,
  border,
  matchFn = (optionValue, currentValue) => optionValue === currentValue,
  ...rest
}: ToggleButtonProps<V>) {
  return (
    <ToggleButtonBase stretch={stretch} border={border} {...rest}>
      {options.map((option) => (
        <ToggleButtonOption
          type="button"
          key={option.value}
          active={matchFn(option.value, value)}
          tabIndex={0}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </ToggleButtonOption>
      ))}
    </ToggleButtonBase>
  );
}
