import { Theme } from "@emotion/react";
import get from "lodash/get";

import { COLOR_SCHEME_CLASS_PREFIX } from "../context";
import { FlattenObjectKeys } from "../types";
import { darkColors } from "./darkColors";
import { lightColors } from "./lightColors";
import { ColorScheme } from "./theme";

export type ThemeProps = { theme: Partial<Theme> };

export const getColors = (scheme: ColorScheme) => {
  switch (scheme) {
    case "light": {
      return lightColors;
    }
    case "dark": {
      return darkColors;
    }
  }
};

export const getColorVars = (scheme: ColorScheme) =>
  Object.entries(getColors(scheme)).map(([key, value]) => `--color-${key}: ${value};`);

export const mediaScreen = (query: string, invert = false) =>
  `@media${invert ? " not " : " "}screen and ${query}`;

export const media =
  (breakpoint: keyof Theme["breakpoints"], invert = false) =>
  (props: ThemeProps) => {
    return mediaScreen((props.theme as Theme).breakpoints[breakpoint], invert);
  };

export const token =
  (key: FlattenObjectKeys<Omit<Theme, "setColorScheme">>) =>
  (props: ThemeProps): string => {
    return get(props.theme, key)!;
  };

export const stripVar = (variable: string) => {
  return variable.match(/var\(([a-zA-Z0-9-_]+)\)/)?.[1] ?? variable;
};

export const colorModeValue =
  (
    lightModeValue: string | ((props: ThemeProps) => string),
    darkModeValue: string | ((props: ThemeProps) => string)
  ) =>
  (props: ThemeProps) => {
    if (props.theme.colorScheme === "dark") {
      return typeof darkModeValue === "function" ? darkModeValue(props) : darkModeValue;
    }

    return typeof lightModeValue === "function" ? lightModeValue(props) : lightModeValue;
  };

export const darkMode = () => {
  return `.${COLOR_SCHEME_CLASS_PREFIX}dark &`;
};
