import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { ThemeProps, token } from "../../tokens";

export type ParagraphVariant =
  | "faded"
  | "faded-blue"
  | "uppercase"
  | "small"
  | "warning"
  | "error"
  | "inter"
  | "viga"
  | "strong";

const variantStyles = (p: ThemeProps) => {
  return {
    faded: css`
      color: ${token("colors.dark")(p)};
    `,
    "faded-blue": css`
      color: ${token("colors.darker")(p)};
    `,
    uppercase: css`
      text-transform: uppercase;
    `,
    small: css`
      font-size: 1rem;
    `,
    warning: css`
      color: ${token("colors.warning")(p)} !important;
    `,
    error: css`
      color: ${token("colors.alert")(p)} !important;
    `,
    inter: css`
      font-weight: normal;
      font-family: ${token("fonts.inter")(p)};
    `,
    viga: css`
      font-weight: normal;
      font-family: ${token("fonts.viga")(p)};
    `,
    strong: css`
      font-weight: bold;
    `,
  } as const;
};

export const Paragraph = styled.p<{
  variant?: ParagraphVariant | ParagraphVariant[];
  textAlign?: "left" | "center" | "right";
}>`
  font-size: 1.0625rem;
  line-height: 1.5;

  ${(p) =>
    p.textAlign &&
    css`
      text-align: ${p.textAlign};
    `};

  ${(p) =>
    p.variant &&
    (Array.isArray(p.variant)
      ? p.variant.map((singleVariant) => variantStyles(p)[singleVariant])
      : variantStyles(p)[p.variant])}
`;
