import { Box, Flex, Grid, Heading, Paragraph, Stack } from "@blox/design-system";
import { useTheme } from "@emotion/react";
import { useEffect, useRef } from "react";

import PaperBox from "../../components/UI/Paper/PaperBox";
import { extendedLocales, useLocalisation } from "../../context/LocalizeProvider";
import useScript from "../../hooks/useScript";
import { useLocale } from "../../hooks/useStore";
import getColor from "../../utils/getColor";
import { newlineToBR } from "../../utils/newlineToBR";
import { ImageWrapper, PaymentMethods } from "./About.styles";
import bank from "./images/bank.png";
import { ReactComponent as BankTransferIcon } from "./images/bank-transfer.svg";
import { ReactComponent as BancontactIcon } from "./images/bankcontact.svg";
import coreValue1 from "./images/core-value-1.png";
import coreValue2 from "./images/core-value-2.png";
import coreValue3 from "./images/core-value-3.png";
import coreValue4 from "./images/core-value-4.png";
import coreValue5 from "./images/core-value-5.png";
import coreValue6 from "./images/core-value-6.png";
import { ReactComponent as CreditCardIcon } from "./images/creditcard.svg";
import { ReactComponent as IdealIcon } from "./images/ideal.svg";
import photo1 from "./images/photo1.jpg";
import photo2 from "./images/photo2.jpg";
import photo3 from "./images/photo3.jpg";
import photo4 from "./images/photo4.jpg";
import photo5 from "./images/photo5.jpg";
import photo6 from "./images/photo6.jpg";
import users from "./images/users.png";

function TrustPilot() {
  const { colorScheme } = useTheme();
  const locale = useLocale();
  const ref = useRef<HTMLDivElement>(null!);

  const status = useScript(`//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js`);

  useEffect(() => {
    if (status === "ready") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)?.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [status]);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={extendedLocales[locale]}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5d1dc412e24c7700017fd7f9"
      data-style-height="110px"
      data-style-width="100%"
      data-theme={colorScheme}
    >
      <a
        href={`https://${extendedLocales[locale]}.trustpilot.com/review/www.weareblox.com`}
        target="_blank"
        rel="noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
}
function About() {
  const t = useLocalisation();

  return (
    <Box maxWidth={840} marginBottom={{ base: 0, excludePhone: 50 }}>
      <Heading level={1}>{t.text("About", "title")}</Heading>
      <Stack space={24}>
        <PaperBox contentPadding>
          <Stack space={24}>
            <Heading level={2}>{t.text("About", "block_1_title")}</Heading>
            <Paragraph>{newlineToBR(t.text("About", "block_1_text"))}</Paragraph>
            <Grid gridTemplateColumns="1fr 1fr" gap={{ base: 16, excludePhone: 32 }}>
              <Box gridColumn="span 2">
                <ImageWrapper>
                  <img src={photo1} alt="" />
                </ImageWrapper>
              </Box>
              <ImageWrapper>
                <img src={photo2} alt="" />
              </ImageWrapper>
              <ImageWrapper>
                <img src={photo3} alt="" />
              </ImageWrapper>
            </Grid>
          </Stack>
        </PaperBox>
        <PaperBox contentPadding>
          <Grid gridTemplateColumns="1fr 1fr 1fr" gap={{ base: 8, excludePhone: 32 }} textAlign="center">
            <Flex flexDirection="column" gap={20}>
              <Flex flexGrow={1} alignItems="center" justifyContent="center">
                <img src={users} alt="" css={{ maxWidth: "40%" }} />
              </Flex>
              <Paragraph>{t.text("About", "usp1")}</Paragraph>
            </Flex>
            <Flex flexDirection="column" gap={20}>
              <Flex flexGrow={1} alignItems="center" justifyContent="center">
                <TrustPilot />
              </Flex>
              <Paragraph>{t.text("About", "usp2")}</Paragraph>
            </Flex>
            <a
              href="https://blog.weareblox.com/blox-receives-approval-of-dutch-central-bank-as-the-first-consumer-focused-cryptocurrency-company-2e152768c9a3"
              target="blank"
              rel="noopener noreferrer"
              css={{
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                color: "inherit",
                "&:hover, &:focus": { color: getColor("bloxBlueDark") },
              }}
            >
              <Flex flexGrow={1} alignItems="center" justifyContent="center">
                <img src={bank} alt="" css={{ maxWidth: "40%" }} />
              </Flex>
              <Paragraph>{t.text("About", "usp3")}</Paragraph>
            </a>
          </Grid>
        </PaperBox>
        <PaperBox contentPadding>
          <Stack space={24}>
            <Heading level={2}>{t.text("About", "block_2_title")}</Heading>
            <Paragraph>{newlineToBR(t.text("About", "block_2_text"))}</Paragraph>
            <Grid gridTemplateColumns="1fr 1fr 1fr" gap={{ base: 16, excludePhone: 32 }}>
              <Box>
                <ImageWrapper size="square">
                  <img src={photo4} alt="" />
                </ImageWrapper>
              </Box>
              <ImageWrapper size="square">
                <img src={photo5} alt="" />
              </ImageWrapper>
              <ImageWrapper size="square">
                <img src={photo6} alt="" />
              </ImageWrapper>
            </Grid>
          </Stack>
        </PaperBox>
        <PaperBox contentPadding>
          <Stack space={48}>
            <Heading level={2}>{t.text("About", "block_3_title")}</Heading>
            <Grid
              gridTemplateColumns={{ base: "1fr 1fr", excludePhone: "1fr 1fr 1fr" }}
              gap={{ base: 16, excludePhone: 32 }}
              textAlign="center"
            >
              <Flex flexDirection="column" alignItems="center">
                <img src={coreValue1} alt="" css={{ maxWidth: "6.5rem" }} />
                <Paragraph variant="strong">Love what you build</Paragraph>
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <img src={coreValue2} alt="" css={{ maxWidth: "6.5rem" }} />
                <Paragraph variant="strong">Create Fans</Paragraph>
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <img src={coreValue3} alt="" css={{ maxWidth: "6.5rem" }} />
                <Paragraph variant="strong">Take Ownership</Paragraph>
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <img src={coreValue4} alt="" css={{ maxWidth: "6.5rem" }} />
                <Paragraph variant="strong">Keep It Simple Stupid</Paragraph>
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <img src={coreValue5} alt="" css={{ maxWidth: "6.5rem" }} />
                <Paragraph variant="strong">Celebrate The Small Wins</Paragraph>
              </Flex>
              <Flex flexDirection="column" alignItems="center">
                <img src={coreValue6} alt="" css={{ maxWidth: "6.5rem" }} />
                <Paragraph variant="strong">Bulls Over Bears</Paragraph>
              </Flex>
            </Grid>
          </Stack>
        </PaperBox>
        <PaperBox contentPadding>
          <Stack space={24}>
            <Heading level={2}>{t.text("About", "block_4_title")}</Heading>
            <Paragraph>{newlineToBR(t.text("About", "block_4_text"))}</Paragraph>
            <PaymentMethods>
              <li>
                <IdealIcon /> <Paragraph>iDEAL</Paragraph>
              </li>
              <li>
                <CreditCardIcon /> <Paragraph>Credit Card</Paragraph>
              </li>
              <li>
                <BancontactIcon /> <Paragraph>Bancontact</Paragraph>
              </li>
              <li>
                <BankTransferIcon /> <Paragraph>Sepa Bank Transfer</Paragraph>
              </li>
            </PaymentMethods>
          </Stack>
        </PaperBox>
      </Stack>
    </Box>
  );
}

export default About;
